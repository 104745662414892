import { BsCheck2, BsExclamationCircle } from "react-icons/bs"
import { useRouter } from "next/router"
import { Box, Typography } from "@mui/material"
import { GridColDef, GridEventListener } from "@mui/x-data-grid-pro"
import pluralize from "pluralize"

import { useSeamQuery } from "hooks/useSeamQuery"

import { useUrlQuery } from "providers/UrlQueryProvider"

import DataGridResults from "components/data/DataGrid/DataGridResults"
import NoRowsOverlay from "components/data/DataGrid/NoRowsOverlay"
import SearchOverlay from "components/data/DataGrid/SearchOverlay"
import IconText from "components/data/IconText"
import DevicePassCount from "components/pages/devices/DevicePassCount"
import EmptyOverlay from "components/pages/home/BuildingsDataGrid/Results/EmptyOverlay"

import { icons } from "lib/constants/icons"
import { colorDefaults } from "lib/mui"

import { Building } from "types"

const useBuildingCountSummary = (building_id: string) => {
  const { data: summary } = useSeamQuery(
    ["buildings", { building_id }, "get_count_summary"],
    (seam) => seam.buildings.get_count_summary({ building_id }),
    { enabled: Boolean(building_id) }
  )
  return summary
}

const IssueCountCell: GridColDef<Building>["renderCell"] = (props) => {
  const summary = useBuildingCountSummary(props.row.building_id)
  if (!summary) return null
  if (summary.incidents > 0) {
    return (
      <IconText
        fillAndTextColor={colorDefaults.status.redAlert}
        icon={<BsExclamationCircle />}
        label={`${summary.incidents} ${pluralize("issue", summary.incidents)}`}
      />
    )
  }
  return <IconText icon={<BsCheck2 />} label={`All ${summary?.devices}`} />
}

const DevicePassCountCell: GridColDef<Building>["renderCell"] = (props) => {
  const summary = useBuildingCountSummary(props.row.building_id)
  if (!summary) return null
  return <DevicePassCount passCount={summary.active_access_passes} />
}

const columns: GridColDef<Building>[] = [
  {
    field: "name",
    type: "string",
    renderCell: (props) => (
      <Box alignItems="center" display="flex">
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          height={24}
          width={24}
          mr={2}
        >
          {icons.building}
        </Box>
        <Typography
          fontSize="1.125rem"
          fontWeight={600}
          lineHeight="1.5rem"
          variant="subtitle1"
        >
          {props.value}
        </Typography>
      </Box>
    ),
    flex: 1.2,
    minWidth: 120,
  },
  {
    align: "left",
    field: "issue_count",
    type: "number",
    renderCell: IssueCountCell,
    flex: 0.7,
    minWidth: 70,
  },
  {
    align: "left",
    field: "active_access_pass_count",
    type: "number",
    renderCell: DevicePassCountCell,
    flex: 0.7,
    minWidth: 70,
  },
]

interface Props {
  buildings?: Building[]
  isLoading: boolean
}

const Results = ({ buildings, isLoading }: Props) => {
  const router = useRouter()

  const { filterKey, search } = useUrlQuery()

  const goToBuilding: GridEventListener<"rowClick"> = (r) =>
    router.push(`/buildings/${r.id}`)

  if (!buildings) {
    return null
  }

  const hasBuildings = buildings.length > 0
  const showingEmptyOverlay =
    !isLoading && !hasBuildings && !search.term && !filterKey

  if (showingEmptyOverlay) {
    return <EmptyOverlay />
  }

  return (
    <DataGridResults
      columns={columns}
      items={buildings}
      isLoading={isLoading}
      getRowId={(b) => b.building_id}
      isSearching={search.isActive}
      searchTerm={search.term}
      onRowClick={goToBuilding}
      noRowsOverlay={
        <NoRowsOverlay
          handleCancelSearch={search.cancel}
          searching={search.isActive}
          unit="building"
        />
      }
      searchOverlay={<SearchOverlay>Type a building name</SearchOverlay>}
    />
  )
}

export default Results

import { useRouter } from "next/router"
import Button from "@mui/material/Button"

import { useIsAdmin } from "hooks/auth/useIsAdmin"

import { usePanels } from "providers/PanelsProvider"

import BuildingIcon from "assets/images/building.svg"

interface DescriptionProps {
  children: string
}
const Description = ({ children }: DescriptionProps) => {
  if (!children) {
    return null
  }

  return <p className="description">{children}</p>
}

const AddBuildingButton = () => {
  const panels = usePanels()
  const isAdmin = useIsAdmin()

  if (!isAdmin) {
    return null
  }

  return (
    <Button
      variant="contained"
      color="secondary"
      size="small"
      onClick={async () => {
        panels.set("addBuildingModal", true)
      }}
    >
      Add building
    </Button>
  )
}

const EmptyOverlay = () => {
  const isAdmin = useIsAdmin()

  const title = isAdmin ? "No buildings yet" : "No buildings assigned yet"
  const description = isAdmin
    ? ""
    : "Contact your admin to have buildings assigned to you."

  return (
    <div className="buildings-empty-overlay">
      <BuildingIcon />
      <span className="title">{title}</span>
      <Description>{description}</Description>
      <AddBuildingButton />
    </div>
  )
}

export default EmptyOverlay

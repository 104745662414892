import { useEffect } from "react"
import { useRouter } from "next/router"
import { Button } from "@mui/material"
import ms from "ms"

import useMyOrganization from "hooks/auth/useMyOrganization"
import useMyOrgRole from "hooks/auth/useMyOrgRole"
import { useSeamQuery } from "hooks/useSeamQuery"
import useSessionStore from "hooks/useSessionStore"

import { usePanels } from "providers/PanelsProvider"

import Private from "templates/Private"

import Tile from "components/cards/Tile"
import WithSidebar from "components/layout/WithSidebar"
import BuildingsDataGrid from "components/pages/home/BuildingsDataGrid"
import TitleBar from "components/pages/TitleBar"
import Sidebar from "components/shell/Sidebar"
import HTMLTitle from "components/utility/HTMLTitle"

import { getSavedObject } from "lib/utils/storage"

const Home = () => {
  const router = useRouter()
  const panels = usePanels()
  const organization = useMyOrganization()
  // The session store has access to the organization_id before useMyOrganization
  const organization_id = useSessionStore((state) => state.organization_id)

  const { data: buildings, isLoading: areBuildingsLoading } = useSeamQuery(
    ["buildings", {}, "list"],
    (seam) => seam.buildings.list({})
  )

  const { data: organizationSummary } = useSeamQuery(
    ["organizations", { organization_id }, "get_count_summary"],
    (seam) =>
      seam.organizations.get_count_summary({
        between: [new Date(0), new Date()],
        organization_id: organization_id!,
      })
  )

  const { total_devices } = organizationSummary ?? {}

  const { data: incidents, isLoading: areIncidentsLoading } = useSeamQuery(
    ["incidents", {}, "list"],
    (seam) =>
      seam.incidents.list({
        // Last 30 days
        between: [new Date(Date.now() - ms("30d")), new Date()],
      })
  )

  const hasIncidents = () => {
    if (typeof incidents === "undefined") return false
    return incidents.length > 0
  }

  const queries = ["is:online"]
  const { data: deviceCounts } = useSeamQuery(
    ["devices", {}, "list_counts", { queries }],
    (seam) => seam.devices.list_counts({ queries })
  )

  const { data: buildingCounts } = useSeamQuery(
    ["buildings", {}, "get_count_summary"],
    (seam) => seam.buildings.get_count_summary({})
  )

  const myRole = useMyOrgRole().userRole

  useEffect(() => {
    const organizationIds = getSavedObject<string[]>(
      "panel:hide-org-setup-modal",
      []
    )

    if (
      (buildings?.length === 0 || total_devices === 0) &&
      !organizationIds.includes(organization_id ?? "null") &&
      myRole !== "org:member"
    ) {
      panels.set("orgSetupModal", true)
    }
  }, [buildingCounts])

  useEffect(() => {
    if (typeof organization_id === "undefined") {
      router.push("/organizations/no-org")
    }
  }, [organization_id])

  return (
    <div className="home-page">
      <HTMLTitle>Buildings</HTMLTitle>

      <WithSidebar
        sidebar={
          <Sidebar.Home
            isLoading={areBuildingsLoading || areIncidentsLoading}
            buildings={buildings ?? []}
            incidents={incidents?.slice(0, 10) ?? []}
          />
        }
      >
        <section className="content section pt-0">
          <div className="grid grid-3 tl-grid-2 ml-grid-1 mb-8">
            <Tile
              value={(deviceCounts?.[0].count as number) ?? 0}
              label="Devices online"
            />
            <Tile
              value={(buildingCounts?.active_access_passes as number) ?? 0}
              label="Access passes"
            />
            <Tile
              value={(incidents?.length as number) ?? 0}
              label="Device issues"
              variant={hasIncidents() ? "error" : "default"}
              onClick={() =>
                panels.set("issueModal", !panels.get("issueModal")?.isOpen)
              }
            />
          </div>
          <TitleBar
            title={organization?.name ?? "Home"}
            caption={`${buildings?.length || "0"} buildings`}
            rightAreaContent={
              <Button
                variant="outlined"
                onClick={async () => {
                  panels.set("addBuildingModal", true)
                }}
                sx={{
                  ml: 2,
                }}
              >
                Create New Building
              </Button>
            }
            noBorder
          />
          <BuildingsDataGrid />
        </section>
      </WithSidebar>
    </div>
  )
}

Home.template = Private

export default Home

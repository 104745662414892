import {
  DataGridPro,
  DataGridProProps,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"

interface ResultsProps<T extends GridValidRowModel>
  extends Pick<DataGridProProps, "columns" | "onRowClick"> {
  items: T[]
  getRowId: (item: T) => string | number
  isLoading: boolean
  noRowsOverlay: JSX.Element
  isSearching: boolean
  searchTerm: string | null
  searchOverlay: JSX.Element
}

const DataGridResults: <T extends GridValidRowModel>(
  props: ResultsProps<T>
) => JSX.Element = ({
  columns,
  items,
  isLoading,
  getRowId,
  onRowClick,
  noRowsOverlay,
  isSearching,
  searchTerm,
  searchOverlay,
}) => {
  const showingSearchOverlay = isSearching && searchTerm?.length === 0
  if (showingSearchOverlay) {
    return searchOverlay
  }

  return (
    <div style={{ width: "100%" }}>
      <DataGridPro
        autoHeight
        columns={columns}
        components={{
          NoRowsOverlay: () => noRowsOverlay,
        }}
        disableSelectionOnClick
        headerHeight={0}
        loading={isLoading}
        onRowClick={onRowClick}
        pageSize={100}
        pagination
        rowHeight={80}
        rows={items}
        filterMode="server"
        sortingMode="server"
        getRowId={getRowId}
      />
    </div>
  )
}

export default DataGridResults

import { Box, Skeleton, SxProps, Typography } from "@mui/material"

import QuickActionMenu from "components/pages/buildings/QuickActionMenu"
import PageTitle from "components/typography/PageTitle"

import type { Children } from "types"

interface Props extends Children {
  title: string
  caption?: string
  isLoading?: boolean
  rightAreaContent?: React.ReactNode
  noBorder?: boolean
  showQuickActionMenu?: boolean
  sx?: SxProps
}

const TitleBar = ({
  title,
  caption,
  isLoading,
  rightAreaContent,
  noBorder,
  showQuickActionMenu,
  sx,
}: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1.25rem 0",
        mb: 0,
        borderBottom: noBorder ? "none" : "1px solid #EEEEEE",
        ...sx,
      }}
    >
      {isLoading && (
        <Skeleton animation="wave" variant="rounded" height={20} width="33%" />
      )}
      {!isLoading && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <PageTitle>{title}</PageTitle>
            {showQuickActionMenu && <QuickActionMenu />}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {caption && (
              <Typography
                variant="caption"
                sx={{
                  fontSize: "0.875rem",
                }}
              >
                {caption}
              </Typography>
            )}
            {rightAreaContent}
          </Box>
        </>
      )}
    </Box>
  )
}

export default TitleBar
